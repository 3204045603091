import "./index.css";
import React, { useState, useEffect, useRef } from "react";
import Select from 'react-select';
import { Col, Button, Row, Form, Container, Table, Modal, Nav } from 'react-bootstrap';
import { useLocation, useNavigate, useSearchParams, Link} from "react-router-dom";
import { useCookies } from "react-cookie";
import {
    isTokenValid, insertUpdateSparepartMaintenancePlan, findInventoryMasterDetailBySparepartCatalogId, 
    getPeriod, deleteSparepartMaintenancePlan, deleteSparepartCatalogDocument, getDocumentSparepartById, getVesselMaintenancePlanListByVesselEquipmentId,
    getUserAccessById, getCountSparepartCatalogDocument, getVesselMaintenancePlan, insertUpdateVesselMaintenancePlan, deleteVesselMaintenancePlan
} from "../../../Helpers/ApplicationHelpers";
import "../../../App.css";
import Navbar from "../../../Components/NavBar";
import Paginations from "../../../Components/Pagination";
import Modals from "../../../Components/modal";
import { Editor } from '@tinymce/tinymce-react';
import {
    Trash, ArrowLeft, Download, FilePlusFill, EyeFill, XSquareFill,
    ChevronDoubleLeft, ChevronDoubleRight, ZoomIn, ZoomOut, AspectRatioFill, Search, ArrowClockwise, PencilFill,
    SaveFill, XCircle, CameraFill,
    Save2,
    Check2Circle,
    Newspaper
} from "react-bootstrap-icons";
import ContainerBox from "../../../Components/ContainerBox";
import Loading from "../../../Components/Loading";
import TextBox from "../../../Components/Textbox";
import FormSelect from "../../../Components/FormSelect";
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { triggerBase64Download } from "../../../Helpers/Base64Downloader";
import moment from 'moment';
import Sidebar from "../../../Components/NewSideBarForCustomerPage/NewSideBar";
import { Trans, useTranslation } from "react-i18next";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


export default function CustomerVesselEquipmentMaintenancePlanPage() {
    const inputFile = useRef(null);
    const { t, i18n } = useTranslation();
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [updatedButton, setUpdatedButton] = useState("");
    const [deletedButton, setDeletedButton] = useState("");
    const [loading, setLoading] = useState(true);
    const [sparepartCatalogId, setSparepartCatalogId] = useState(0);
    // const [sparepartCatalog, setSparepartCatalog] = useState({});
    const [listMaintenancePlan, setListMaintenancePlan] = useState([]);
    const [listPeriod, setListPeriod] = useState([]);
    const [showMaintenancePlanDetailModal, setShowMaintenancePlanDetailModal] = useState(false);
    const [selectedMaintenancePlan, setSelectedMaintenancePlan] = useState({});
    const [maintenancePlanToDeleteId, setMaintenancePlanToDeleteId] = useState(0);
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(50);
    const [totalPage, setTotalPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [itemPerPageSelection, setItemPerPageSelection] = useState([5, 10, 15, 20, 50]);
    const [orderBy, setOrderBy] = useState({
        label: `${t("sparepart_catalog_document.order_name1")}`,
        value: "document_name"
    });
    const orderByList = [{
        label: `${t("sparepart_catalog_document.order_name1")}`,
        value: "document_name"
    }];

    const [sparepartCatalog, setSparepartCatalog] = useState({
        id: "",
        inventory_master_name: "",
        inventory_group_name: "",
        inventory_subgroup_name: "",
        inventory_material_name: "",
        inventory_brand_name: "",
        part_number: "",
});


    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/Customer/Login");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }
                console.log(searchParams.get("sparepartCatalogId"));
                setSparepartCatalogId(searchParams.get("sparepartCatalogId"));

                // loadAccess();
                // loadListPeriod();
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        if (sparepartCatalogId) {
            loadListMaintenancePlan();
            setSparepartCatalog({
                id: searchParams.get("sparepartCatalogId"),
                inventory_master_name: searchParams.get("sparepartName"),
                inventory_group_name: searchParams.get("sparepartGroup"),
                inventory_subgroup_name: searchParams.get("sparepartSubGroup"),
                inventory_material_name: searchParams.get("sparepartMaterial"),
                inventory_brand_name: searchParams.get("sparepartBrand"),
                part_number: searchParams.get("sparepartPartNumber"),
            })
             
            // console.log(searchParams.get("sparepartGroup"));
            // console.log(searchParams.get("sparepartSubGroup"));
            // initSparepartCatalog();
        }

    }, [sparepartCatalogId]);

    // useEffect(() => {
    //     if (sparepartCatalog) {
    //         console.log('sparepart catalog detail:', sparepartCatalog);
    //         loadListMaintenancePlan();
    //     }

    // }, [sparepartCatalog]);

    useEffect(() => {
        if (sparepartCatalog) {
            console.log(sparepartCatalog);
            loadAccess();
            loadListPeriod();
            loadListMaintenancePlan();
        }

    }, [sparepartCatalog]);

    // useEffect(() => {
    //     if (sparepartCatalogId) {
    //         console.log("SPAREPARTCATALOGID : " + sparepartCatalogId);

    //         setSparepartCatalog(location.state.sparepartCatalog);
    //     }

    // }, [sparepartCatalogId]);

    // useEffect(() => {
    //     if (sparepartCatalog) {
    //         console.log(sparepartCatalog);
    //         loadListMaintenancePlan();
    //     }

    // }, [sparepartCatalog]);


    useEffect(() => {
        console.log(listMaintenancePlan);
        if (listMaintenancePlan){
            setLoading(false);
        }
    }, [listMaintenancePlan]);

    const loadListMaintenancePlan = async () => {
        try {
            setLoading(true);
            if (sparepartCatalogId !== 0) {
                let results = await getVesselMaintenancePlanListByVesselEquipmentId(cookies.token, cookies.languageId, sparepartCatalog.id, page, itemPerPage, orderBy , false);
                console.log('resultmaintenanceplanvessel:', results);
                if (results) {
                    setListMaintenancePlan(results);
                }
            }
        }
        catch (exception) {
            setLoading(false);
            console.log(exception);
        }
    };

    // const initSparepartCatalog = async () => {
    //     try {
    //         if (sparepartCatalogId !== "") {
    //             // setSelectedManufacturer({});
    //             let response = await findInventoryMasterDetailBySparepartCatalogId(cookies.token, sparepartCatalogId);
    //             console.log("Init Sparepart", response[0]);
    //             if (response) {
    //                 setSparepartCatalog({
    //                     id: response[0].id,
    //                     inventory_master_name: response[0].inventory_master_name,
    //                     division_id: response[0].division_id,
    //                     unit: response[0].unit,
    //                     reference_price: response[0].reference_price,
    //                     brand: response[0].brand,
    //                     model: response[0].model,
    //                     quantity:response[0].quantity,
    //                     inventory_master_description: response[0].inventory_master_description,
    //                     inventory_master_note: response[0].inventory_master_note,
    //                     created_date: response[0].created_date,
    //                     updated_date: response[0].updated_date,
    //                     created_by: response[0].created_by,
    //                     updated_by: response[0].updated_by,
    //                     is_active: response[0].is_active,
    //                     inventory_type_id: response[0].inventory_type_id,
    //                     language_id: response[0].language_id,
    //                     inventory_unit_id: response[0].inventory_unit_id,
    //                     ext_customer_id: response[0].ext_customer_id,
    //                     is_consumeable: response[0].is_consumeable,
    //                     lead_time:response[0].lead_tim,
    //                     buffer_stock:response[0].buffer_stoc,
    //                     inventory_brand_id: response[0].inventory_brand_id,
    //                     taken_date: response[0].taken_date,
    //                     inventory_material_id: response[0].inventory_material_id,
    //                     inventory_master_alias: response[0].inventory_master_alias,
    //                     inventory_subtype_id: response[0].inventory_subtype_id,
    //                     inventory_group_id: response[0].inventory_group_id,
    //                     is_production_material: response[0].is_production_material,
    //                     inventory_subgroup_id: response[0].inventory_subgroup_id,
    //                     part_number: response[0].part_number,
    //                     weight:response[0].weight,
    //                     inventory_group_name: response[0].inventory_group_name,
    //                     inventory_subgroup_name: response[0].inventory_subgroup_name,
    //                     inventory_material_name: response[0].inventory_material_name,
    //                     brand_name:response[0].brand_name,
    //                 });

    //                 // let eqp = await getEquipment(cookies.token);
    //                 // let selectedEqp = eqp.find(p => p.id === response[0].equipment_id);
    //                 // // console.log(selectedEqp);
    //                 // setSelectedCategory(selectedEqp);

    //             }
    //         }

    //         setLoading(false);
    //     } catch (exception) {
    //         console.log(exception);
    //     }
    // }

    const loadListPeriod = async () => {
        try {
            let results = await getPeriod(cookies.token, cookies.languageId);
            if (results) {
                setListPeriod(results);
            }
        }
        catch (exception) {
            console.log(exception);
        }
    }

    const saveMaintenancePlan = async () => {
        try {
            console.log('selectedmaintplan:', selectedMaintenancePlan);
            let selectedMPTMP = selectedMaintenancePlan;
            selectedMPTMP.vessel_id = sparepartCatalog.vessel_id;
            selectedMPTMP.equipment_id = sparepartCatalog.id;
            selectedMPTMP.parent_id = sparepartCatalog.parent_id;
            console.log("selectedMPTMP", selectedMPTMP);
            let response = await insertUpdateVesselMaintenancePlan(cookies.token, selectedMPTMP, cookies.languageId);

            if (response.error_code === 0) {
                alert(`${t("sparepart_catalog_maintenance_plan.alert_success_save_data")}`);
                console.log("selectedMPTP  :  --------  " + selectedMPTMP.id);
                loadListMaintenancePlan();

            } else {
                alert(`${t("sparepart_catalogue.alert_failed_save_data")}`);
            }
            setSaveButtonDisabled(false);
            setShowMaintenancePlanDetailModal(false);
        }
        catch (exception) {
            console.log(exception);
            alert(`${t("sparepart_catalogue.alert_failed_save_data")}`);
            setSaveButtonDisabled(false);
        }
    }

    const resetMaintenancePlanDetailForm = () => {
        setSelectedMaintenancePlan({
            id: 0,
            vessel_id: "",
            equipment_id: "",
            sparepart_catalog_id: sparepartCatalogId,
            maintenance_plan_name: "",
            maintenance_plan_desc: "",
            maintenance_plan_period_count: 0,
            is_periodic: false,
            is_repeating: false,
            period_name: "",
            hours_count: 0,
            total_period_hours: 0,
            running_hour_interval: 0,
            use_contact_count: false,
            contact_count: 0
        });
    }

    const deleteMaintenancePlan = async (maintenancePlanId) => {
        try {
            
            let res = await deleteVesselMaintenancePlan(cookies.token, maintenancePlanId, cookies.languageId);
            if (res) {
                alert(t("sparepart_catalog_maintenance_plan.delete_success_alert"));
            } else {
                alert(t("sparepart_catalog_maintenance_plan.delete_failed_alert"));
            }
            loadListMaintenancePlan();
        }
        catch (exception) {
            console.log(exception);
            alert(t("sparepart_catalog_maintenance_plan.delete_failed_alert"));
        }
    };

    const loadAccess = async () => {
        try {
            // console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            console.log(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")));
            console.log(response)
            if (response) {
                setDeletedButton(response.deleted);
                setUpdatedButton(response.updated)
            }
            setOrderBy({
                label: `${t("sparepart_catalog_document.order_name1")}`,
                value: "document_name"
            })
        } catch (exception) {
            console.log(exception);
        }
    }

    const styles = {
        valueContainer: (base) => ({
            ...base,
            maxHeight: 60,
            overflowY: "auto",
            width: "100%"
        }),
    }
    return (
        <>
            <div className="mainPartMPNew">
                <Sidebar companyName={searchParams.get("customerName")} smsSerialNumber={searchParams.get("smsSerialnumber")} vesselId={searchParams.get("vesselId")} vesselName={searchParams.get("vesselName")} customerName={cookies.userName} />
                <Container fluid className="mainContainerMPNew">
                    <div className="childMainContainerMPNew">
                        <ContainerBox
                    titleCaption={`${t("sparepart_catalog_maintenance_plan.header_title")}`}
                    useActionContainer={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? false : true}
                    actionContainerChild={
                        <div>
                            {/* <Button disabled={false} variant="secondary" style={{

                            }} onClick={() => {
                                resetMaintenancePlanDetailForm();
                                setShowMaintenancePlanDetailModal(true);
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><FilePlusFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>{t("sparepart_catalog_maintenance_plan.add_button")}</div>
                                </div>
                            </Button> */}
                        </div>
                    }
                    childContent={
                        <div className="master-table-inner-container">
                            {sparepartCatalog &&
                                <div style={{
                                    flex: 1,
                                    alignItems: "center"
                                }}>
                                    <div style={{
                                        flex: 1,
                                        padding: 5,
                                    }}>
                                        <div style={{ width: "100%", border: '2px solid black', borderRadius: '5px' }}>
                                            <div style={{ display: "flex", justifyContent: 'space-around', width: "100%" }}>
                                                <div style={{ display: "flex", flexDirection: 'column', flexWrap: "nowrap", width: "100%", padding: '10px' }}>
                                                    <Form.Group style={{
                                                        width: "100%",
                                                        paddingLeft: 5,
                                                        flex: 1,
                                                    }} className="mb-3">
                                                        <TextBox disabledForm={true} value={sparepartCatalog.inventory_master_name} placeholder=""
                                                            caption={t("sparepart_catalog_maintenance_plan.inventory_name")}
                                                            req={true} />
                                                    </Form.Group>
                                                    <Form.Group style={{
                                                        width: "100%",
                                                        paddingLeft: 5,
                                                        flex: 1,
                                                    }} className="mb-3">
                                                        <TextBox disabledForm={true} value={sparepartCatalog.inventory_group_name} placeholder=""
                                                            caption={t("sparepart_catalog_maintenance_plan.inventory_group")}
                                                            req={true} />
                                                    </Form.Group>
                                                    <Form.Group style={{
                                                        width: "100%",
                                                        paddingLeft: 5,
                                                        flex: 1,
                                                    }} className="mb-3">
                                                        <TextBox disabledForm={true} value={sparepartCatalog.inventory_subgroup_name} placeholder=""
                                                            caption={t("sparepart_catalog_maintenance_plan.inventory_subgroup")}
                                                            req={true} />
                                                    </Form.Group>

                                                </div>
                                                <div style={{ display: "flex", flexDirection: 'column', flexWrap: "nowrap", width: "100%", padding: '10px' }}>
                                                    <Form.Group style={{
                                                        width: "100%",
                                                        paddingLeft: 5,
                                                        flex: 1,
                                                    }} className="mb-3">
                                                        <TextBox disabledForm={true} value={sparepartCatalog.inventory_material_name} placeholder=""
                                                            caption={t("sparepart_catalog_maintenance_plan.inventory_material")}
                                                            req={true} />
                                                    </Form.Group>
                                                    <Form.Group style={{
                                                        width: "100%",
                                                        paddingLeft: 5,
                                                        flex: 1,
                                                    }} className="mb-3">
                                                        <TextBox disabledForm={true} value={sparepartCatalog.inventory_brand_name} placeholder=""
                                                            caption={t("sparepart_catalog_maintenance_plan.inventory_brand")}
                                                            req={true} />
                                                    </Form.Group>
                                                    <Form.Group style={{
                                                        width: "100%",
                                                        paddingLeft: 5,
                                                        flex: 1,
                                                    }} className="mb-3">
                                                        <TextBox disabledForm={true} value={sparepartCatalog.part_number} placeholder=""
                                                            caption={t("sparepart_catalog_maintenance_plan.inventory_part_number")}
                                                            req={true} />
                                                    </Form.Group>


                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {/* <div className="master-table-searchbar-container">
                                <div className="master-table-searchbar-textbox">
                                    <Form.Control value={searchQuery} type="text" placeholder={`${t("sparepart_catalog_maintenance_plan.search_box")}`}
                                        onKeyPress={async (e) => {
                                            if (e.charCode === 13) {
                                                setPage(0);
                                                setLoading(true);

                                            }
                                        }}
                                        onChange={(e) => {

                                        }} />
                                </div>
                                <div className="master-table-searchbar-button">
                                    <div style={{ paddingRight: 5 }}>
                                        <Button onClick={async () => {
                                            setPage(0);
                                            setLoading(true);

                                        }}><Search /></Button>
                                    </div>
                                    <div style={{ paddingLeft: 5 }}>
                                        <Button onClick={async () => {
                                            setLoading(true);
                                            setSearchQuery("");

                                        }}><ArrowClockwise /></Button>
                                    </div>
                                </div>
                            </div> */}
                            <div className="table-container">
                                <div className="table-header">
                                    <div style={{ flex: 1 }} className="table-header-content">#</div>
                                    <div style={{ flex: 4 }} className="table-header-content">{t("sparepart_catalog_maintenance_plan.header_name")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("sparepart_catalog_maintenance_plan.header_count")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("sparepart_catalog_maintenance_plan.header_period_long")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("sparepart_catalog_maintenance_plan.header_maintenance_history")}</div>
                                    {/* <div style={{ flex: 2 }} className="table-header-content">{t("sparepart_catalog_maintenance_plan.header_use_contact_count")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("sparepart_catalog_maintenance_plan.header_is_repeating")}</div>
                                    <div style={{ flex: 2 }} className="table-header-content">{t("sparepart_catalog_maintenance_plan.header_list_image")}</div> */}
                                    <div style={{ flex: 2 }} className="table-header-content">{t("sparepart_catalog_maintenance_plan.header_description")}</div>
                                    {/* <div style={{ flex: 2 }} className="table-header-content">{t("sparepart_catalog_maintenance_plan.header_delete")}</div> */}
                                </div>
                                {
                                    listMaintenancePlan.map((maint, index) => {
                                        return (<div key={index} className="table-body">
                                            <div style={{ flex: 1 }} className="table-body-content"><p>{index + 1}</p></div>
                                            <div style={{ flex: 4 }} className="table-body-content"><p>{maint.maintenance_plan_name}</p></div>
                                            <div style={{ flex: 2 }} className="table-body-content">
                                                {maint.is_periodic && <p>{`${String(maint.maintenance_plan_period_count)}`}</p>}
                                                {maint.use_contact_count && <p>{`${String(maint.contact_count)} Contact`}</p>}
                                            </div>
                                            <div style={{ flex: 2 }} className="table-body-content">
                                                {maint.is_periodic && <p>{`${maint.period_name}`}</p>}
                                                {maint.use_contact_count && <p>{`${String(maint.contact_count)} Contact`}</p>}
                                            </div>
                                            {/* <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                <Form.Check
                                                    className="checkbox-lg"
                                                    checked={maint.is_periodic}
                                                    label=""
                                                    name="is_periodic"
                                                    disabled
                                                    type={"checkbox"}
                                                    id={`periodic-chk-${index}`}
                                                />
                                            </div>
                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                <Form.Check
                                                    className="checkbox-lg"
                                                    checked={maint.use_contact_count}
                                                    label=""
                                                    name="use_contact_count"
                                                    disabled
                                                    type={"checkbox"}
                                                    id={`contact-count-chk-${index}`}
                                                />
                                            </div>
                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                <Form.Check
                                                    className="checkbox-lg"
                                                    checked={maint.is_repeating}
                                                    label=""
                                                    name="is_repeating"
                                                    disabled
                                                    type={"checkbox"}
                                                    id={`repeating-chk-${index}`}
                                                />
                                            </div> */}
                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                <Link to={`/Customer/VesselEquipment/MaintenancePlan/MaintenanceHistory?maintenancePlanId=${maint.id}&maintenancePlanName=${maint.maintenance_plan_name}&vesselId=${maint.vessel_id}&equipmentId=${maint.equipment_id}`} target="_blank">
                                                    <Button disabled={false} variant="primary" onClick={() => {
                                                        console.log('maint.id:', maint.id);
                                                        console.log('maint.name:', maint.maintenance_plan_name);
                                                        // navigate("/SparepartCatalog/MaintenancePlan/Image", { state: { maintenancePlanId: maint.id, maintenancePlanName: maint.maintenance_plan_name } })
                                                    }}><Newspaper />
                                                    </Button>
                                                </Link>
                                                </div></div>
                                            <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button disabled={false} variant="primary" onClick={() => {
                                                        setSelectedMaintenancePlan(maint)
                                                        setShowMaintenancePlanDetailModal(true);
                                                    }}><EyeFill />
                                                    </Button>
                                                </div></div>
                                            {/* <div style={{ flex: 2, justifyContent: "center" }} className="table-body-content">
                                                <div style={{ padding: 5 }}>
                                                    <Button disabled={cookies.logingAs === "Customer" && cookies.extCustomerId !== "null" ? true : !deletedButton} variant="danger" onClick={() => {
                                                        if (window.confirm(`${t("sparepart_catalog_maintenance_plan.delete_confirmation")}  ${maint.maintenance_plan_name}?`)) {
                                                            deleteMaintenancePlan(maint.id);
                                                        }
                                                    }}><Trash />
                                                    </Button>
                                                </div></div> */}
                                        </div>)
                                        
                                    
                                    }
                                    
                                    )
                                }
                            </div>
                            <div style={{
                                paddingTop: 20,
                            }}>

                            </div>

                            <Loading
                                loading={loading}
                                loadingText={`${t("loading_component.text")}`}
                            />
                        </div>
                    } />
                    </div>
                </Container>
                <Modal show={showMaintenancePlanDetailModal}

                size={"xxl"}
                onHide={() => {
                    setShowMaintenancePlanDetailModal(false);

                }}>
                <div className="details m-2" >
                    <div className="detailscontent">
                        {/* {selectedMaintenancePlan.id === 0 && <h3>{t("sparepart_catalog_maintenance_plan.modal_add_maintenance_plan")} </h3>} */}
                        {<h3>{t("sparepart_catalog_maintenance_plan.modal_maintenance_plan_description")} </h3>}
                    </div>

                    <Form onSubmit={(e) => {
                        // setSaveButtonDisabled(true);
                        // e.preventDefault();
                        // saveMaintenancePlan();
                    }} style={{ padding: 10 }}>
                        <TextBox disabledForm={true} value={selectedMaintenancePlan.maintenance_plan_name} placeholder=""
                            caption={t("sparepart_catalog_maintenance_plan.field_maintenance_plan_name")}
                            // setValueFunction={(e) => {
                            //     setSelectedMaintenancePlan({ ...selectedMaintenancePlan, maintenance_plan_name: e.target.value });
                            // }}
                            req={true} />
                        <Form.Group className="mb-3">
                            <Form.Label>{t("sparepart_catalog_maintenance_plan.field_maintenance_plan_desc")}</Form.Label>
                            {/* <Form.Control as="textarea" rows={3} 
                            onChange={(e) => {
                                setVesselEquipmentToBeSaved({ ...vesselEquipmentToBeSaved, description: e.target.value })
                            }} 
                            type="text" value={selectedMaintenancePlan.maintenance_plan_desc} placeholder=""></Form.Control> */}
                            <Editor
                                apiKey='pbjm9oydw65b4p46e89nz1eav5rqqpy66e0ltrlnr15kc5dq'
                                // onInit={(evt, editor) => setContractTask({ ...contractTask, description: editor})}
                                // onEditorChange={(evt, editor) => {
                                //     setSelectedMaintenancePlan({ ...selectedMaintenancePlan, maintenance_plan_desc: evt });

                                // }}
                                disabled
                                value={selectedMaintenancePlan.maintenance_plan_desc}
                                    init={{
                                        height: 500,
                                        menubar: false,
                                        plugins: [
                                            'advlist', 'autolink',
                                            'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                                            'fullscreen', 'insertdatetime', 'media', 'table', 'help', 'wordcount', 'code'
                                        ],
                                        toolbar1: 'undo redo |  bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | outdent indent ',
                                        toolbar2: 'fontsize fontfamily |' + 'bullist numlist checklist  |   table  |  code fullscreen',
                                        image_title: true,
                                        automatic_uploads: true,
                                    /*
                                      URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
                                      images_upload_url: 'postAcceptor.php',
                                      here we add custom filepicker only to Image dialog
                                    */
                                    file_picker_types: 'image',
                                    /* and here's our custom image picker*/
                                    file_picker_callback: (cb, value, meta) => {
                                        const input = document.createElement('input');
                                        input.setAttribute('type', 'file');
                                        input.setAttribute('accept', 'image/*');

                                        input.addEventListener('change', (e) => {
                                            const file = e.target.files[0];
                                            const reader = new FileReader();
                                            reader.addEventListener('load', () => {
                                                /*
                                                  Note: Now we need to register the blob in TinyMCEs image blob
                                                  registry. In the next release this part hopefully won't be
                                                  necessary, as we are looking to handle it internally.
                                                */

                                                // console.log(editorRef.current.getContent())
                                                const id = 'blobid' + (new Date()).getTime();
                                                const blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                                                //   editorRef.current.editorUpload.blobCache

                                                //Editor.activeEditor.editorUpload.blobCache;

                                                const base64 = reader.result.split(',')[1];
                                                const blobInfo = blobCache.create(id, file, base64);
                                                blobCache.add(blobInfo);

                                                /* call the callback and populate the Title field with the file name */
                                                cb(blobInfo.blobUri(), { title: file.name });
                                            });
                                            reader.readAsDataURL(file);
                                        });

                                        input.click();
                                    },
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                }}
                            />
                        </Form.Group>
                        <div style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "row",
                            width: "100%"
                        }}>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "row",
                                width: "100%"
                            }}>
                                <Form.Group style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%"
                                }} className="mb-3">
                                    <Form.Label>{t("sparepart_catalog_maintenance_plan.field_is_periodic")}</Form.Label>
                                    <Form.Check
                                        checked={selectedMaintenancePlan.is_periodic}
                                        label=""
                                        name="selected_is_repeating"
                                        type={"checkbox"}
                                        id={`selected_repeating-chk`}
                                        onChange={(e) => {
                                            setSelectedMaintenancePlan({ ...selectedMaintenancePlan, is_periodic: e.target.checked });
                                        }}
                                        disabled
                                    />
                                </Form.Group>
                                <Form.Group style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%"
                                }} className="mb-3">
                                    <Form.Label>{t("sparepart_catalog_maintenance_plan.field_is_repeating")}</Form.Label>
                                    <Form.Check
                                        checked={selectedMaintenancePlan.is_repeating}
                                        label=""
                                        name="selected_is_repeating"
                                        type={"checkbox"}
                                        id={`selected_repeating-chk`}
                                        onChange={(e) => {
                                            setSelectedMaintenancePlan({ ...selectedMaintenancePlan, is_repeating: e.target.checked });
                                        }}
                                        disabled
                                    />
                                </Form.Group>
                                <Form.Group style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%"
                                }} className="mb-3">
                                    <Form.Label>{t("sparepart_catalog_maintenance_plan.field_use_contact_count")}</Form.Label>
                                    <Form.Check
                                        checked={selectedMaintenancePlan.use_contact_count}
                                        label=""
                                        name="selected_use_contact_count"
                                        type={"checkbox"}
                                        id={`selected_contact-chk`}
                                        onChange={(e) => {
                                            setSelectedMaintenancePlan({ ...selectedMaintenancePlan, use_contact_count: e.target.checked });
                                        }}
                                        disabled
                                    />
                                </Form.Group>
                            </div>

                        </div>
                        <div style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "row",
                            width: "100%"
                        }}>
                            <Form.Group className="mb-3">
                                <TextBox style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "row",

                                }} disabledForm={true} value={selectedMaintenancePlan.maintenance_plan_period_count} placeholder=""
                                    caption={t("sparepart_catalog_maintenance_plan.field_maintenance_plan_period_count")}
                                    setValueFunction={(e) => {
                                        setSelectedMaintenancePlan({ ...selectedMaintenancePlan, maintenance_plan_period_count: e.target.value });
                                    }}
                                    req={true} />
                            </Form.Group>
                            <div style={{ paddingLeft: 10 }}></div>
                            <Form.Group className="mb-3">
                                <Form.Label>{t("sparepart_catalog_maintenance_plan.field_maintenance_plan_period")}</Form.Label>
                                <Form.Select disabled={true} onChange={(e) => {
                                    if (e.target.value !== "") {
                                        setSelectedMaintenancePlan({ ...selectedMaintenancePlan, period_id: e.target.value })
                                    } else {
                                        setSelectedMaintenancePlan({ ...selectedMaintenancePlan, period_id: null })
                                    }

                                }} value={selectedMaintenancePlan.period_id ? selectedMaintenancePlan.period_id : ""}>
                                    <option selected value="">

                                    </option>
                                    {listPeriod.map((position, index) => (
                                        <option key={index} value={position.id}>{position.period_name}</option>
                                    ))}
                                    
                                </Form.Select>
                            </Form.Group>
                            <div style={{ paddingLeft: 10 }}></div>
                            <Form.Group className="mb-3">
                                <Form.Label>{t("sparepart_catalog_maintenance_plan.field_or")}</Form.Label>

                            </Form.Group>
                            <div style={{ paddingLeft: 10 }}></div>
                            <Form.Group className="mb-3">
                                <TextBox style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "row",

                                }} disabledForm={true} value={selectedMaintenancePlan.running_hour_interval} placeholder=""
                                    caption={t("sparepart_catalog_maintenance_plan.field_maintenance_plan_running_hour_interval")}
                                    setValueFunction={(e) => {
                                        setSelectedMaintenancePlan({ ...selectedMaintenancePlan, running_hour_interval: e.target.value });
                                    }}
                                    req={true} />
                            </Form.Group>
                            <div style={{ paddingLeft: 10 }}></div>
                            <Form.Group className="mb-3">
                                <TextBox style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "row",

                                }} disabledForm={true} value={selectedMaintenancePlan.contact_count} placeholder=""
                                    caption={t("sparepart_catalog_maintenance_plan.field_contact_count")}
                                    setValueFunction={(e) => {
                                        setSelectedMaintenancePlan({ ...selectedMaintenancePlan, contact_count: e.target.value });
                                    }}
                                    req={true} />
                            </Form.Group>
                        </div>
                        <div style={{ paddingBottom: 10 }}></div>
                        <div style={{
                            display: "flex",
                            flex: 1,
                            flexWrap: "nowrap",
                            flexDirection: "row",
                            justifyContent: "center"
                        }}>
                            {/* <div style={{ display: "flex", width: "100%" }}>
                                <Button style={{ width: "100%" }} type="submit" disabled={saveButtonDisabled} ><Check2Circle />{` ${t("sparepart_catalog_maintenance_plan.save_button")}`}</Button>
                            </div>
                            <div style={{ display: "flex", width: "100%" }}>
                                <Button style={{ width: "100%" }} className="cancel" variant="danger" onClick={() => {
                                    setShowMaintenancePlanDetailModal(false);
                                }}><XCircle />{` ${t("sparepart_catalog_maintenance_plan.cancel_button")}`}</Button>
                            </div> */}


                        </div>
                    </Form>
                </div>
            </Modal>
        </div>

        </>
    );

}